<template>
  <v-container
    id="facture-detail"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="7"
      >
        <base-material-card
          id="card-facture-detail"
          color="primary"
        >
          <template v-slot:heading>
            <div class="display-3">
              Facture n° {{ facture.numero }} ({{ facture.reference }})
            </div>
          </template>

          <v-card-text class="text-center body-1">
            <v-row class="align-left">
              <v-col cols="12">
                <v-icon class="mx-2">mdi-traffic-light</v-icon>
                <b class="mx-2">État :</b><span v-html="displayEtat()"></span>
              </v-col>
              <v-col cols="12">
                <v-icon class="mx-2">mdi-barcode-scan</v-icon>
                <b class="mx-2">Référence :</b>
                <span>{{ facture.reference }}</span>
              </v-col>
              <v-col
                cols="12"
                v-if="facture.intitule"
              >
                <v-icon class="mx-2">mdi-form-textbox</v-icon>
                <b class="mx-2">Intitulé :</b>
                <span>{{ facture.intitule }}</span>
              </v-col>
              <v-col cols="12">
                <v-icon class="mx-2">mdi-briefcase-variant</v-icon>
                <b class="mx-2">Client :</b>
                <router-link :to="{ name: 'ClientDetail', params: { id: facture.client.id } }">{{ facture.client.nomCommercial }}</router-link>
              </v-col>
              <v-col
                cols="12"
                v-if="facture.client.id !== facture.destinataire.id"
              >
                <v-icon class="mx-2">mdi-hand-coin</v-icon>
                <b class="mx-2">Financeur :</b>
                <router-link :to="{ name: 'FinanceurDetail', params: { id: facture.destinataire.id } }">{{ facture.destinataire.nomCommercial }}</router-link>
              </v-col>
              <v-col cols="12">
                <v-icon class="mx-2">mdi-card-account-mail-outline</v-icon>
                <b class="mx-2">Contacts facturation :</b>
                <span>{{ facture.destinataire.contactsFacturation.map(c => c.prenom + ' ' + c.nom + ' (' + c.email + ')').join(', ') }}</span>
              </v-col>
              <v-col
                cols="12"
                v-if="facture.formationAssociee"
              >
                <v-icon class="mx-2">mdi-human-male-board</v-icon>
                <b class="mx-2">Formation associée :</b>
                <router-link :to="{ name: 'FormationDetail', params: { id: facture.formationAssociee.id } }">{{ facture.formationAssociee.intitule }} ({{ facture.formationAssociee.reference }}) du {{ new Date(facture.formationAssociee.dateDebut).toLocaleString('fr-FR').substring(0, 10) }}</router-link>
              </v-col>
              <v-col cols="12">
                <v-icon class="mx-2">mdi-folder-plus-outline</v-icon>
                <b class="mx-2">Date d'émission :</b>
                <span>{{ new Date(facture.dateEmission).toLocaleString('fr-FR').substring(0, 10) }}</span>
              </v-col>
              <v-col
                cols="12"
                v-if="facture.etat !== 'NOUVEAU'"
              >
                <v-icon class="mx-2">mdi-send-circle-outline</v-icon>
                <b class="mx-2">Date d'envoi :</b>
                <span>{{ new Date(facture.dateEnvoi).toLocaleString('fr-FR').substring(0, 10) }}</span>
              </v-col>
              <v-col
                cols="12"
                v-if="facture.etat !== 'NOUVEAU'"
              >
                <v-icon class="mx-2">mdi-watch-vibrate</v-icon>
                <b class="mx-2">Date d'échéance :</b>
                <span>{{ new Date(facture.dateEcheance).toLocaleString('fr-FR').substring(0, 10) }}</span>
              </v-col>
              <v-col
                cols="12"
                v-if="facture.etat === 'ACQUITTE'"
              >
                <v-icon class="mx-2">mdi-check-circle</v-icon>
                <b class="mx-2">Date de paiement :</b>
                <span>{{ new Date(facture.datePaiement).toLocaleString('fr-FR').substring(0, 10) }}</span>
              </v-col>
              <v-col cols="12">
                <v-icon class="mx-2">mdi-cash</v-icon>
                <b class="mx-2">Montant HT :</b>
                <span>{{ toEuro(facture.montantHT) }}</span>
              </v-col>
              <v-col cols="12">
                <v-icon class="mx-2">mdi-cash</v-icon>
                <b class="mx-2">TVA :</b>
                <span>{{ toEuro(facture.montantTVA) }}</span>
              </v-col>
              <v-col cols="12">
                <v-icon class="mx-2">mdi-cash-multiple</v-icon>
                <b class="mx-2">Montant TTC :</b>
                <span>{{ toEuro(facture.montantTotal) }}</span>
              </v-col>
              <v-col
                cols="12"
                v-for="[title, value] of Object.entries(facture.informationsAdditionnelles)"
                :key="title"
              >
                <v-icon class="mx-2">mdi-information-variant-circle</v-icon>
                <b class="mx-2">{{ title }} :</b>
                <span>{{ value }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="5"
      >
        <base-material-card
          color="primary"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Actions
            </div>
          </template>
          <v-card-text class="text-center">
            <v-btn
              v-if="facture.etat === 'NOUVEAU'"
              color="primary"
              rounded
              class="ma-2"
              @click="$refs.dialogNewEmailRef.show('FACTURE', { facture: facture })"
              :disabled="envoyerDisabled"
            >
              <v-icon left>
                mdi-send
              </v-icon>
              Envoyer
            </v-btn>
            <v-btn
              v-if="facture.etat === 'NOUVEAU'"
              color="#725bcf"
              rounded
              class="ma-2"
              @click="mailEnvoye()"
            >
              <v-icon left>
                mdi-send-check
              </v-icon>
              Marquer comme envoyée
            </v-btn>
            <v-btn
              v-if="facture.etat === 'ENVOYE'"
              color="green"
              rounded
              class="ma-2"
              @click="acquitter()"
            >
              <v-icon left>
                mdi-stamper
              </v-icon>
              Acquitter
            </v-btn>
            <!--
            <v-btn
              v-if="facture.payee"
              color="green"
              rounded
              class="ma-2"
              disabled
            >
              <v-icon left>
                mdi-bell
              </v-icon>
              Payée
            </v-btn>
            -->
            <v-btn
              v-if="facture.etat === 'NOUVEAU'"
              color="blue"
              rounded
              class="ma-2"
              @click="$router.push({ name: 'ModifierFacture', params: { id: facture.id } })"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              Modifier
            </v-btn>
            <v-btn
              v-if="isDerniereFacture && facture.etat === 'NOUVEAU'"
              color="red"
              rounded
              class="ma-2"
              @click="dialogSupprimer = true"
            >
              <v-icon left>
                mdi-delete
              </v-icon>
              Supprimer
            </v-btn>
            <v-btn
              color="#2a63b9"
              rounded
              class="ma-2"
              @click="downloadDocx()"
              :disabled="downloadDocxDisabled"
            >
              <v-icon left>
                mdi-download
              </v-icon>
              Télécharger au format DOCX
            </v-btn>
            <v-btn
              color="#c74f46"
              rounded
              class="ma-2"
              @click="downloadPdf()"
              :disabled="downloadPdfDisabled"
            >
              <v-icon left>
                mdi-download
              </v-icon>
              Télécharger au format PDF
            </v-btn>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogSupprimer"
      max-width="560px"
    >
      <v-card>
        <v-card-title
          class="headline"
        >
          Souhaitez-vous supprimer cette facture ?
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="dialogSupprimer = false"
          >
            Non
          </v-btn>
          <v-btn
            color="primary"
            @click="supprimerFacture()"
          >
            Oui
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-new-email
      ref="dialogNewEmailRef"
      @mailEnvoiEnCours="envoyerDisabled = true"
      @mailEnvoye="mailEnvoye()"
    />
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {

    components: {
      DialogNewEmail: () => import('../../component/DialogNewEmail'),
    },

    data() {
      return {
        facture: {
          client: {
            id: 0,
          },
          destinataire: {
            id: 0,
            contactsFacturation: [],
          },
          informationsAdditionnelles: {},
        },
        envoyerDisabled: false,
        renvoyerDisabled: false,
        downloadDocxDisabled: false,
        downloadPdfDisabled: false,
        isDerniereFacture: false,
        dialogSupprimer: false,
      }
    },

    created () {
      this.axios.get('/facturesSortantes/' + this.$route.params.id)
        .then((res) => {
          this.facture = res.data;
          return this.axios.get('/facturesSortantes/count');
        })
        .then((res) => {
          if (this.facture.numero === res.data) {
            this.isDerniereFacture = true;
          }
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération de la facture', type: 'warn' });
        });
    },

    methods: {
      goBackFactures() {
        this.$router.push({ name: 'Factures' });
      },

      displayEtat() {
        switch (this.facture.etat) {
          case 'NOUVEAU':
            return '<span id="facture-etat" class="badge-chips badge-gray">En attente d\'envoi</span>';
          case 'ENVOYE':
            return '<span id="facture-etat" class="badge-chips badge-blue">En attente de paiement</span>';
          case 'ACQUITTE':
            return '<span id="facture-etat" class="badge-chips badge-green">Payée</span>';
          case 'RETARD':
            return '<span id="facture-etat" class="badge-chips badge-orange">En retard de paiement</span>';
        }
      },

      formatPhone(number) {
        return number.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ' ');
      },

      envoyer() {
        this.envoyerDisabled = true;
        // this.renvoyerDisabled = true;
        this.axios.post('/facturesSortantes/' + this.facture.reference + '/sendMail')
          .then((res) => {
            this.facture = res.data;

            this.items[0] = {
              icon: 'mdi-traffic-light',
              title: 'État',
              value: '<span id="facture-etat" class="badge-chips badge-green">Envoyée</span>',
            };
            this.setSnack({ text: 'Facture envoyée', type: 'success' });
            this.envoyerDisabled = false;
            // this.renvoyerDisabled = false;
            this.items.splice(7, 0, {
              icon: 'mdi-send-circle-outline',
              title: 'Date d\'envoi',
              value: new Date(this.facture.dateEnvoi).toLocaleString('fr-FR').substring(0, 10),
            }, {
              icon: 'mdi-watch-vibrate',
              title: 'Date d\'échéance',
              value: new Date(this.facture.dateEcheance).toLocaleString('fr-FR').substring(0, 10),
            });
            this.$forceUpdate();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de l\'envoi de la facture', type: 'warn' });
            this.envoyerDisabled = true;
            // this.renvoyerDisabled = true;
          });
      },

      acquitter() {
        this.axios.patch('/facturesSortantes/' + this.facture.id + '/acquitter')
          .then((res) => {
            this.facture = res.data;
            this.$forceUpdate();
            this.setSnack({ text: 'La facture a été acquittée', type: 'success' });
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la mise-à-jour de la facture', type: 'warn' });
          });
      },

      mailEnvoye() {
        this.envoyerDisabled = false;
        this.axios.patch('/facturesSortantes/' + this.facture.id + '/mailEnvoye')
          .then((res) => {
            this.facture = res.data;
            this.$forceUpdate();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la mise-à-jour de la facture', type: 'warn' });
          });
        this.$forceUpdate();
      },

      downloadDocx() {
        this.downloadDocxDisabled = true;
        this.axios.get('/documents/facture/' + this.facture.id + '/docx', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/docx' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.facture.reference + '.docx');
            document.body.appendChild(link);
            link.click();
            this.downloadDocxDisabled = false;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de la facture', type: 'warn' });
            this.downloadDocxDisabled = false;
          });
      },

      downloadPdf() {
        this.downloadPdfDisabled = true;
        this.axios.get('/documents/facture/' + this.facture.id + '/pdf', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.facture.reference + '.pdf');
            document.body.appendChild(link);
            link.click();
            this.downloadPdfDisabled = false;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de la facture', type: 'warn' });
            this.downloadPdfDisabled = false;
          });
      },

      supprimerFacture() {
        this.axios.delete('/facturesSortantes/' + this.facture.id)
          .then((res) => {
            this.$router.push({ name: 'Factures' });
            this.dialogSupprimer = false;
            this.setSnack({ text: 'La facture a bien été supprimée', type: 'success' });
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la suppression de la facture', type: 'warn' });
            this.dialogSupprimer = false;
          });
      },

      toEuro(num) {
        if (isNaN(num)) {
          num = 0;
        }
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(num);
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }

</script>

<style scoped>
  .align-left {
    text-align: left;
  }
  .no-underline {
    text-decoration: none;
  }

  #facture-detail >>> .badge-chips {
    padding: 6px 12px;
    font-weight: 600;
    border-radius: 4px;
  }

  #facture-detail >>> .badge-gray {
    background-color: #a6a8ae26;
    color: #63646a;
  }

  #facture-detail >>> .badge-blue {
    background-color: #1967c026;
    color: #135095;
  }

  #facture-detail >>> .badge-green {
    background-color: #00968826;
    color: #02643f;
  }

  #facture-detail >>> .badge-orange {
    background-color: #e88f1326;
    color: #905709;
  }
</style>
